import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Typography,
    Button,
    List,
    ListItem,
    ListItemText,
    CircularProgress,
} from "@mui/material";
import { usePayment } from "../context/PaymentContext";
import { ReactComponent as TickIcon } from "../../../../assets/images/tick_green.svg";
import { clearCartData } from "../../../../redux/actions/restaurantDataActions";
import moment from "moment";
import { useEffect, useState } from "react";

const OrderSuccessPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { paymentSuccessful, setPaymentSuccessful } = usePayment();
    const [orderUpdationError, setOrderUpdationError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const orderDetails = useSelector((state) => state.restaurantData);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_APP_API_URL}stripe/${location.state.orderId}/refresh-payment`,
                    {
                        method: "GET",
                        headers: { "Content-Type": "application/json" },
                    }
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                console.log("data => ", data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setOrderUpdationError(error.message);
                setIsLoading(false);
            }
        }

        fetchData();
    }, [location.state.orderId]);

    const handleBackToRestaurantButtonClick = async () => {
        setPaymentSuccessful(false);
        dispatch(clearCartData());
        navigate(`/restaurant_menu/${orderDetails.restaurantDetail._id}`);
    };

    if (!paymentSuccessful) {
        return (
            <Navigate
                to={`/restaurant_menu/${orderDetails.restaurantDetail._id}`}
                replace
            />
        );
    }

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
                <Typography ml={2}>Creating your order...</Typography>
            </Box>
        );
    }

    if (orderUpdationError) {
        return (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
                <Typography color="error" variant="h6">Error processing your order</Typography>
                <Typography>{orderUpdationError}</Typography>
                <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
                    Retry
                </Button>
            </Box>
        );
    }

    return (
        <Box
            display='flex'
            flexDirection='column'
            alignItems='stretch'
            height='100vh'
            boxSizing='border-box'
        >
            <Box
                sx={{
                    height: "84px",
                    backgroundColor: "#000000",
                    color: "#ffffff",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "24px",
                    fontFamily: "GilroyMedium",
                    flex: "0 0 auto",
                }}
            >
                Thank You
            </Box>

            <Box p={2} m={1} sx={styles.dataView}>
                <Box pt={2} sx={styles.topView}>
                    <TickIcon width={70} height={70} />
                    <Typography mt={2} sx={styles.thankyouText}>
                        Thank you!
                    </Typography>
                    <Typography sx={styles.orderPlacedText}>
                        Your order #{location.state.orderId.toString().substr(-5)} has
                        been placed.&lrm;
                    </Typography>
                </Box>
                <Typography mt={3} sx={styles.sendEmailText}>
                    We sent an email with your order confirmation and bill.&lrm;
                </Typography>
                <Typography mt={3} sx={styles.timeText}>
                    Time placed: {moment().format("DD/MM/Y HH:mm A")}
                </Typography>
            </Box>

            <Box p={2} mx={1} sx={styles.dataView}>
                <Box sx={styles.topView}>
                    <Typography sx={styles.thankyouText}>Your Order</Typography>

                    <List>
                        {orderDetails.cartItems.map((item, index) => (
                            <ListItem key={index}>
                                <ListItemText
                                    primary={`${item.name} x ${item.count}`}
                                    primaryTypographyProps={{ sx: styles.titleText }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Box>

            <Box p={2} m={1} sx={styles.dataView}>
                <Box sx={styles.topView}>
                    <Typography sx={styles.thankyouText}>Your Bill</Typography>

                    <Box className={styles.listView}>
                        <Typography
                            className={styles.totalVal}
                            sx={{ fontFamily: "GilroySemiBold" }}
                        >
                            £ {location.state.amount}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Button
                variant='contained'
                color='primary'
                onClick={handleBackToRestaurantButtonClick}
                sx={{
                    color: "#ffffff",
                    backgroundColor: "#000000",
                    height: "58px",
                    borderRadius: "58px",
                    minWidth: "347px",
                    fontFamily: "GilroySemiBold",
                    fontSize: "20px",
                    textTransform: "none",
                    position: "fixed",
                    bottom: "20px",
                    left: "50%",
                    transform: "translateX(-50%)",
                }}
            >
                Back to Restaurant
            </Button>
        </Box>
    );
};

export default OrderSuccessPage;

const styles = {
    dataView: {
        backgroundColor: "#FFF",
        borderRadius: "15px",
    },
    topView: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    thankyouText: {
        color: "#212529",
        fontSize: 22,
        fontFamily: "GilroyBlack",
        textAlign: "center",
    },
    orderPlacedText: {
        color: "#212529",
        fontSize: 16,
        fontFamily: "GilroyMedium",
        textAlign: "center",
    },
    sendEmailText: {
        color: "#212529",
        fontSize: 14,
        fontFamily: "GilroySemiBold",
        textAlign: "center",
    },
    timeText: {
        color: "#212529",
        fontSize: 14,
        fontFamily: "GilroySemiBold",
        textAlign: "center",
    },
    addressTitle: {
        color: "#212529",
        fontSize: 15,
        fontFamily: "GilroyBlack",
    },
    titleText: {
        color: "#212529",
        fontSize: 15,
        fontFamily: "GilroySemiBold",
    },
    dataPickupView: {
        backgroundColor: "#FFF",
        position: "fixed",
        top: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        width: "100%",
        overflow: "hidden",
    },
    descText: {
        color: "#212529",
        fontSize: 50,
        lineHeight: "50px",
        fontFamily: "GilroyBold",
        textAlign: "center",
    },
    lottieView: {
        height: "40%",
        display: "flex",
        justifyContent: "center",
    },
};
