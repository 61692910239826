import React, { useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";

import * as Api from "../api/app";

import { connect } from "react-redux";
import {
  clearCartData,
  updateCartItems,
  updatePaymentDetail,
  updateUserAddressList,
  updateRestaurantDetail,
} from "../redux/actions/restaurantDataActions";
import {
  updateCourseList,
  updateMenuList,
  updateRestData,
  updateTableData,
} from "../redux/actions/apiDataActions";

import Loader from "../components/Loader";
import ARWebCartHeader from "../components/ARWebCartHeader";
import ARWebCartFooter from "../components/ARWebCartFooter";
import ARWebCart from "../components/ARWebCart";
import ARWebCheckout from "../components/ARWebCheckout";
import ARWebAddAddress from "../components/ARWebAddAddress";
import ARWebAddressList from "../components/ARWebAddressList";
import ARWebOrderConfirm from "../components/ARWebOrderConfirm";
import AlertMsg from "../components/AlertMsg";
import ARWebOrderTypeModal from "../components/ARWebOrderTypeModal";
import ARWebLanding from "../components/ARWebLanding";
import ARWebCheckoutCartItem from "../components/ARWebCheckoutCartItem";
import ARWebPayment from "../components/ARWebPayment";
import ARWebBilling from "../components/ARWebBilling";
import TableOrderPayNow from "../components/TableOrderPayNow";
import Cart from "./Cart";

import "../assets/css/custom.css";

import { ReactComponent as BackIcon } from "../assets/images/back_black.svg";
import { ReactComponent as FoodoIcon } from "../assets/images/foodo.svg";

import {
  APP_TYPE_FOODO,
  APP_TYPE_GOPOSH,
  CURRENCY_SYMBOL,
  DELIVERY,
  PICKUP,
  TABLE,
  TEMP3REST,
} from "../constants";
import i18next from "../constants/i18n";
import { THEME_COLOR2 } from "./MenuList2";
import {
  checkRestIsClosed,
  getOrdersWithTotalPrice,
  onSetDefaultModifierData,
} from "../constants/commonFunc";
import PaymentDrawer from "./modules/checkout/components/PaymentDrawer";

const pageBottom = (page) => {
  let list = [
    "add_address",
    "add_new_address",
    "edit_address",
    "edit_billing_address",
    "list_address",
    "checkout",
    "order_confirm",
  ];
  return list.includes(page);
};

const pageBg = (page) => {
  let list = [
    "checkout",
    "add_address",
    "add_new_address",
    "edit_address",
    "edit_billing_address",
    "order_confirm",
  ];
  return list.includes(page);
};

let btnViewStyle = {
  temp1: {},
  temp2: {
    fontFamily: "GilroySemiBold",
    backgroundColor: THEME_COLOR2,
    borderRadius: "0px",
    "&:hover": {
      backgroundColor: `${THEME_COLOR2} !important`,
    },
  },
};

const getCartItemList = (cart_item, rest_id) => {
  return JSON.parse(
    JSON.stringify(cart_item.filter((x) => x._idRestaurant == rest_id))
  );
};

const RestaurantCart = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = useParams();

  const appType = props?.restaurantDetail?.appType;

  const [restaurantId, setRestaurantId] = useState(
    location.pathname.split("/").pop() == "cart"
      ? props.restaurantDetail?._id
      : urlParams?.id
      ? urlParams?.id
      : ""
  );
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState("cart");
  const [pageType, setPageType] = useState(
    location.pathname.split("/")[1] == "rstrnt" ? "rstrnt" : "cart"
  );
  const [cartItemList, setCartItemList] = useState(
    getCartItemList(
      props.cartItems,
      location.pathname.split("/").pop() == "cart"
        ? props.restaurantDetail?._id
        : urlParams?.id
        ? urlParams?.id
        : ""
    )
  );
  const [shippingAddress, setShippingAddress] = useState(null);
  const [editAddress, setEditAddress] = useState(null);
  const [totalCartPrice, setTotalCartPrice] = useState(0);
  const [subTotalCartPrice, setSubTotalCartPrice] = useState(0);
  const [totalCartPriceUnit, setTotalCartPriceUnit] = useState(null);
  const [tableId, setTableId] = useState("");
  const [tableNumber, setTableNumber] = useState(
    urlParams?.table_id ? urlParams?.table_id : ""
  );
  const [orderId, setOrderId] = useState("");
  const [orderData, setOrderData] = useState(null);
  const [transactionId, setTransactionId] = useState("");
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [pageFocus, setPageFocus] = useState(false);
  const [discountVal, setDiscountVal] = useState(0);
  const [tipValue, setTipValue] = useState(0);
  const [taxValue, setTaxValue] = useState(0);
  const [windowOpenUrl, setWindowOpenUrl] = useState("");
  const [orderType, setOrderType] = useState(
    urlParams?.table_id ||
      location.pathname.split("/").pop().startsWith("table")
      ? TABLE
      : props.orderType
  );
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });
  // const [orderTypeModalVisible, setOrderTypeModalVisible] = useState(false);
  const [paymentDetail, setPaymentDetail] = useState({
    method: "card",
    // cardHolderName: "",
    // cardNumber: "",
    // expMonth: "",
    // expYear: "",
    // securityCode: "",
    cardHolderName: "Test user",
    cardNumber: "4539791001730106",
    expMonth: "12",
    expYear: "21",
    securityCode: "289",
  });
  const [restTemplate, setRestTemplate] = useState("temp1");
  const [onlineTablePayment, setOnlineTablePayment] = useState(false);

  const [paymentDrawerOpen, setPaymentDrawerOpen] = useState(false);

  const page_title = {
    // cart: i18next.t(
    //   props.restaurantDetail?._id
    //     ? `${props.restaurantDetail?.name} - ${props.restaurantDetail?.address?.city}`
    //     : "Cart"
    // ),
    cart: i18next.t("Cart"),
    checkout: i18next.t("Checkout"),
    add_address: i18next.t("Add Address"),
    edit_address: i18next.t("Edit Address"),
    edit_billing_address: i18next.t("Edit Address"),
    add_new_address: i18next.t("Add Address"),
    list_address:
      restTemplate == "temp1"
        ? i18next.t("Address")
        : i18next.t("Choose Address"),
    order_confirm: i18next.t("Thank You"),
  };

  const orderTotalPrice = useMemo(() => {
    return (
      getOrdersWithTotalPrice(cartItemList, props.menuList)?.totalPrice ?? 0
    );
  }, [cartItemList, props.menuList]);

  const orderCartItems = useMemo(() => {
    return (
      getOrdersWithTotalPrice(orderData?.cart, props.menuList)?.cartItems ?? []
    );
  }, [orderData?.cart, props.menuList]);

  // useEffect(() => {
  //   if (props.paymentDetail) {
  //     setPaymentDetail(props.paymentDetail);
  //   }
  // }, [props.paymentDetail]);

  useEffect(() => {
    if (!TEMP3REST.includes(restaurantId)) {
      setRestTemplate("temp2");
    } else {
      setRestTemplate("temp1");
    }
  }, [restaurantId]);

  useEffect(() => {
    if (props?.restaurantDetail == null) {
      getRestaurant();
    } else if (
      props?.restaurantDetail?.slug == urlParams?.slug &&
      !restaurantId
    ) {
      setRestaurantId(props?.restaurantDetail?._id ?? "");
    }
  }, []);

  useEffect(() => {
    if (props?.restaurantDetail != null) {
      if (
        props?.restaurantDetail?._id != restaurantId &&
        props?.restaurantDetail?.slug != urlParams?.slug
      ) {
        if (cartItemList.length) {
          onRestMenuNavigate();
          return;
        } else {
          getRestaurant();
        }
      }

      if (
        checkRestIsClosed(props?.restaurantDetail?.openHours) ||
        appType === APP_TYPE_FOODO
      ) {
        onRestMenuNavigate();
        return;
      }
    }
    if (orderType == TABLE) {
      if (props?.restaurantDetail?.tableOrder == false || tableNumber == "") {
        onRestMenuNavigate();
        return;
      }
      if (props?.restaurantDetail?.tablePayment == true) {
        setOnlineTablePayment(true);
      }
    } else {
      setOnlineTablePayment(true);
      if (props?.restaurantDetail?.onlineOrder == false) {
        onRestMenuNavigate();
        return;
      }
    }
  }, [restaurantId, props?.restaurantDetail]);

  useEffect(() => {
    if (orderType == TABLE) {
      if (restaurantId && tableId) {
        getMenuList();
      }
    } else {
      setIsLoading(false);
    }
  }, [restaurantId, tableId]);

  useEffect(() => {
    restaurantId && getCourseList();
  }, [restaurantId]);

  useEffect(() => {
    if (cartItemList.length == 0) {
      let cart_item_list = JSON.parse(
        JSON.stringify(
          props.cartItems.filter((x) => x._idRestaurant == restaurantId)
        )
      );
      setCartItemList(cart_item_list);
    }
    if (restaurantId) {
      if (orderType == TABLE) {
        getTableDetailByNUmber();
      } else {
        props.updateTableData(null);
      }
    }
  }, [restaurantId]);

  const getRestaurant = () => {
    let endpoint = restaurantId;
    if (urlParams?.slug) {
      endpoint = "byname/" + urlParams?.slug;
    }
    if (
      props?.restData?._id == restaurantId ||
      (typeof props.restData?.slug != "undefined" &&
        props.restData?.slug == urlParams?.slug)
    ) {
      props.updateRestaurantDetail(props?.restData);
    } else {
      setIsLoading(true);
    }
    Api.getRestaurant(endpoint).then((response) => {
      if (response.success) {
        setRestaurantId(response?.data?._id);
        props.updateRestData(response.data);
        props.updateRestaurantDetail(response.data);
      } else {
        setIsLoading(false);
      }
    });
  };

  const getTableDetailByNUmber = async () => {
    if (
      tableNumber == props.tableData?.name &&
      restaurantId == props.tableData?._idRestaurant
    ) {
      setTableId(props.tableData?._id);
    } else {
      let response = await Api.getTableDetailByNUmber(
        restaurantId,
        tableNumber
      );
      if (response.success) {
        props.updateTableData(response?.data);
        setTableId(response?.data?._id);
      } else {
        setIsLoading(false);
        onRestMenuNavigate();
      }
    }
  };

  const getCourseList = async () => {
    // setIsLoading(true);
    // let response = await Api.getCourseList(restaurantId);
    // if (response.success) {
    //   props.updateCourseList(response?.data?.rows ?? []);
    //   setIsLoading(false);
    // } else {
    //   onRestMenuNavigate();
    // }
  };

  const getMenuList = () => {
    let list = JSON.parse(JSON.stringify(props.menuList)).filter(
      (x) => x?._idRestaurant == restaurantId
    );
    if (list?.length) {
      getTableOrders(list);
    } else {
      let filter =
        "?pageSize=30000&pageNum=1&orderBy=order&orderByDir=asc&filter__idRestaurant=" +
        restaurantId;
      Api.getMenuList(filter).then((response) => {
        if (response.success) {
          let data = response.data.rows;
          props.updateMenuList(data);
          getTableOrders(data);
        } else {
          setIsLoading(false);
        }
      });
    }
  };

  const getTableOrders = (menu_list) => {
    setIsLoading(true);
    Api.getTableOrders(restaurantId, tableId).then((response) => {
      if (response.success) {
        let orders = response.data;
        if (orders == null) {
          if (pageType == "cart") {
            setIsLoading(false);
          } else {
            onRestMenuNavigate();
          }
        } else {
          setOrderId(orders._id);
          setOrderData(orders);
          if (getCartItemList(props.cartItems, restaurantId).length == 0) {
            if (!TEMP3REST.includes(restaurantId)) {
              setPage("cart");
            } else {
              setPage("pay_now");
            }
          }
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        if (pageType == "cart") {
        } else {
          onRestMenuNavigate();
        }
      }
    });
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      document.addEventListener("visibilitychange", onVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let total = orderTotalPrice;
    setSubTotalCartPrice(total.toFixed(2));
    total += tipValue;
    setTotalCartPrice(total.toFixed(2));
    setTotalCartPriceUnit(
      cartItemList.length ? cartItemList[0].price.currency : null
    );
  }, [cartItemList, tipValue, props.menuList, page]);

  useEffect(() => {
    if (cartItemList.length == 0 && orderData) {
      setCartItemList(orderCartItems);
    }
  }, [cartItemList, orderData]);

  useEffect(() => {
    if (page != "edit_address" && page != "edit_billing_address") {
      setEditAddress(null);
    }
  }, [page]);

  useEffect(() => {
    scrollUp();
  }, [page]);

  const scrollUp = () => {
    let sectionLeft = document.querySelector("#mainView");
    if (sectionLeft) {
      sectionLeft.scrollIntoView({ behavior: "auto", block: "start" });
    }
  };

  const onAddSubMenuItemToCart = (type, item) => {
    let list = [...cartItemList];
    let findIndex = list.findIndex((x) => x._id == item._id);
    if (findIndex >= 0) {
      let count = item?.count ? item?.count : 0;
      let options = list[findIndex]?.selectedOptions
        ? list[findIndex]?.selectedOptions
        : [];
      let modifiers = list[findIndex]?.selectedModifiers
        ? list[findIndex]?.selectedModifiers
        : [];
      if (type == "-" && count > 0) {
        count = count - 1;
        // options = options.filter((x, i) => i < count);
        modifiers = modifiers.filter((x, i) => i < count);
      }
      if (type == "+") {
        count = count + 1;

        modifiers = [
          ...modifiers,
          onSetDefaultModifierData(
            list[findIndex]?.modifiers,
            props.menuList,
            "cart"
          ),
        ];
      }
      list[findIndex]["count"] = count;
      // list[findIndex]["selectedOptions"] = options;
      list[findIndex]["selectedModifiers"] = modifiers;

      if (count == 0) {
        list = [...cartItemList].filter((x) => x._id != item._id);
      }
    }
    setCartItemList(list);
    props.updateCartItems(list);
  };

  const onBackBtnSubmit = () => {
    if (page == "cart") {
      if (restaurantId) {
        onRestMenuNavigate();
      } else {
        navigate("/");
      }
    } else if (page == "order_confirm") {
      setCartItemList([]);
      props.clearCartData();
      onRestMenuNavigate();
    } else if (page == "checkout") {
      if (orderType == TABLE) {
        if (!TEMP3REST.includes(restaurantId)) {
          setPage("cart");
        } else {
          setPage("pay_now");
        }
      } else {
        setPage("cart");
      }
    } else if (page == "add_address" || page == "list_address") {
      setPage("checkout");
    } else if (page == "edit_address") {
      setPage("list_address");
    } else if (page == "add_new_address") {
      setPage("list_address");
    } else if (page == "edit_billing_address") {
      setPage("checkout");
    }
  };

  const onBtnSubmit = () => {
    if (page == "cart") {
      setPage("checkout");
      if (
        orderType !== TABLE &&
        props.userAddressList.length &&
        shippingAddress === null
      ) {
        setShippingAddress(props.userAddressList[0]);
      }
    } else if (page == "checkout") {
      onPaymentClick();
    }
  };

  const onSetBillingSameAsDelivery = (val) => {
    let data = { ...shippingAddress, billingSameAsDelivery: val };
    setEditAddress(data);
    setPage("edit_billing_address");
  };

  const onAddAddress = () => {
    setPage("add_address");
  };

  const listAddress = () => {
    setPage("list_address");
  };

  const onSubmitAddAddress = (data) => {
    if (page == "edit_address" || page == "edit_billing_address") {
      let list = [...props.userAddressList];
      let findIndex = list.findIndex((x) => x.id == data.id);
      if (findIndex >= 0) {
        list[findIndex] = data;
      }
      if (shippingAddress?.id == data.id) {
        setShippingAddress(data);
      }
      props.updateUserAddressList(list);
      if (page == "edit_address") {
        setPage("list_address");
      } else {
        setPage("checkout");
      }
    } else if (page == "add_new_address") {
      setPage("list_address");
      setShippingAddress(data);
      props.updateUserAddressList([data, ...props.userAddressList]);
    } else {
      setPage("checkout");
      setShippingAddress(data);
      props.updateUserAddressList([data, ...props.userAddressList]);
    }
  };

  const onCancelAddAddress = () => {
    setPage("checkout");
  };

  const onSubmitAddress = (val) => {
    setShippingAddress(val);
    setPage("checkout");
  };

  const onCancelAddress = () => {
    setPage("checkout");
  };

  const addNewAddressBtnClick = () => {
    setPage("add_new_address");
  };

  const editAddressClick = (data) => {
    setEditAddress(data);
    setPage("edit_address");
  };

  const onSetTipValue = (tip) => {
    setTipValue(tip);
  };

  const backToHomeBtn = () => {
    setCartItemList([]);
    props.clearCartData();
    onRestMenuNavigate();
  };

  const onRestMenuNavigate = () => {
    let url = `/restaurant_menu/${restaurantId}`;
    if (location.pathname.split("/")[1] == "r") {
      url = `/r/menu/${urlParams?.slug}`;
    }
    if (tableNumber) {
      url = `${url}/table/${tableNumber}`;
    }
    navigate(url);
  };

  useEffect(() => {
    if (transactionLoading && transactionId && pageFocus && orderId) {
      getTransactionStatus(orderId, transactionId);
    }
  }, [pageFocus]);

  const onVisibilityChange = (e) => {
    console.log("visiblity", e?.target?.visibilityState);
    setPageFocus(e?.target?.visibilityState == "visible" ? true : false);
  };

  const updatePaymentDetail = (type, value) => {
    let detail = { ...paymentDetail };
    if (type == "method") {
      detail["method"] = value;
    } else if (type == "cardHolderName") {
      detail["cardHolderName"] = value;
    } else if (type == "cardNumber") {
      detail["cardNumber"] = value;
    } else if (type == "expMonth") {
      detail["expMonth"] = value;
    } else if (type == "expYear") {
      detail["expYear"] = value;
    } else if (type == "securityCode") {
      detail["securityCode"] = value;
    }
    setPaymentDetail(detail);
    props.updatePaymentDetail(detail);
  };

  const onPaymentClick = () => {
    if (paymentDetail.method == "") {
      setMsgAlert({
        open: true,
        message: "Payment method required",
        msgType: "error",
      });
      return;
    }

    if (paymentDetail.cardHolderName == "") {
      setMsgAlert({
        open: true,
        message: "Card holder name required",
        msgType: "error",
      });
      return;
    }

    if (paymentDetail.cardNumber == "") {
      setMsgAlert({
        open: true,
        message: "Card number required",
        msgType: "error",
      });
      return;
    }

    if (paymentDetail.expMonth == "") {
      setMsgAlert({
        open: true,
        message: "Card expiry month required",
        msgType: "error",
      });
      return;
    }

    if (paymentDetail.expYear == "") {
      setMsgAlert({
        open: true,
        message: "Card expiry year required",
        msgType: "error",
      });
      return;
    }

    if (paymentDetail.securityCode == "") {
      setMsgAlert({
        open: true,
        message: "CVV required",
        msgType: "error",
      });
      return;
    }

    if (orderType == DELIVERY) {
      if (shippingAddress == null) {
        setMsgAlert({
          open: true,
          message: "Address required",
          msgType: "error",
        });
        return;
      }
    }

    // if (orderType == TABLE) {
    //   let data = {
    //     amount: 1234,
    //     invoice: {
    //       name: props.restaurantDetail?.name,
    //       _idRestaurant: restaurantId,
    //       description: "test",
    //       price: {
    //         value: totalCartPrice,
    //         currency: totalCartPriceUnit,
    //       },
    //     },
    //   };
    //   let datas = JSON.stringify(data);
    //   setTransactionLoading(true);
    //   Api.createTransaction(datas, restaurantId).then((response) => {
    //     if (response.success) {
    //       if (orderType == TABLE) {
    //         orderPayment(orderId, response.data._id);
    //       } else {
    //         createOrder(response.data._id, response.data.status);
    //       }
    //     } else {
    //       setTransactionLoading(false);
    //       setMsgAlert({ open: true, message: response.msg, msgType: "error" });
    //     }
    //   });
    // } else {
    createOrder(null, null);
    // }
  };

  const getTransactionStatus = (order_id, transaction_id) => {
    setTransactionLoading(true);
    let data = {
      transactionId: transaction_id,
    };
    let datas = JSON.stringify(data);

    Api.getTransactionStatus(datas, restaurantId).then((response) => {
      if (response.success) {
        if (response.data.status == "SUCCESS") {
          updateOrder(order_id, transaction_id, response.data.status);
        } else if (response.data.status == "FAILED") {
          updateOrder(order_id, transaction_id, response.data.status);
        }
      } else {
        if (orderType != TABLE) {
          setOrderId("");
          setOrderData(null);
        }
        setTransactionId("");
        setTransactionLoading(false);
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const createOrder = (transaction_id, transaction_status) => {
    setIsLoading(true);
    let cart_items = [];
    if (orderId && orderType == TABLE && orderData?.cart?.length) {
      cart_items = [...orderData.cart];
    }

    let exist_cart_items = cart_items;
    cartItemList.forEach((order) => {
      let options = [];
      if (order?.selectedOptions) {
        order?.selectedOptions.map((opt) => {
          if (opt.length) {
            options.push(opt);
          }
        });
      }

      let modifiers = [];
      let selectedModifiers = order?.selectedModifiers ?? [];
      selectedModifiers.forEach((modifierGroup) => {
        if (modifierGroup.length) {
          const modItems = modifierGroup.map((modifier) => {
            const menuPrice = modifier?.price ?? null;
            const data = {
              _idModifier: modifier._idModifier,
              _idMenuItem: modifier._idMenuItem,
              withExtra: "with",
              price: menuPrice,
            };
            return data;
          });
          modifiers.push(JSON.stringify(modItems));
        }
      });

      let courseData =
        props.courseList?.find((course) =>
          course?._idRestaurantCategory?.includes(order?._idCategory)
        ) ?? null;

      let cdata = {
        _idMenuItem: order._id,
        menuItemName: order.name,
        _idCategory: order._idCategory ?? null,
        _idRestaurantCourse: courseData?._id ?? null,
        restaurantCourseName: courseData?.name ?? "",
        price: {
          value: order.price.value,
          currency: order.price.currency,
        },
        notes: order.note,
        options: JSON.stringify(options),
        modifiers: modifiers,
        quantity: order.count,
      };
      if (exist_cart_items.length) {
        let findIndex = cart_items.findIndex((z) => z._idMenuItem == order._id);
        if (findIndex >= 0) {
          let count = order.count + cart_items[findIndex].quantity;
          let modifiers1 = cart_items[findIndex]?.modifiers;
          let modifier_list = [...modifiers1, ...modifiers];
          cart_items[findIndex] = {
            ...cart_items[findIndex],
            ...cdata,
            quantity: count,
            modifiers: modifier_list,
          };
        } else {
          cart_items.push(cdata);
        }
      } else {
        cart_items.push(cdata);
      }
    });
    let data1 = {
      _idRestaurant: restaurantId,
      totalAmount: Number(subTotalCartPrice) + (orderData?.totalAmount ?? 0),
      cart: cart_items,
    };
    if (orderType == DELIVERY || orderType == PICKUP) {
      data1 = {
        ...data1,
        orderStatus: "incoming",
        transactionId: transaction_id,
        transactionStatus: transaction_status,
      };
    }
    if (orderType == DELIVERY) {
      data1 = {
        ...data1,
        userDetails: {
          name: shippingAddress.name,
          email: shippingAddress.email,
          phone: {
            code: shippingAddress.phoneCode,
            number: shippingAddress.phone,
          },
          address: {
            billingType: shippingAddress.billingType,
            billingSameAsDelivery: shippingAddress.billingSameAsDelivery
              ? 1
              : 0,
            shipping: {
              addressTitle: shippingAddress.addressTitle,
              address: shippingAddress.address,
              pincode: shippingAddress.pincode,
              city: shippingAddress.city,
              country: shippingAddress.country,
            },
            billing: {
              addressTitle: shippingAddress.billingAddressTitle,
              address: shippingAddress.billingAddress,
              pincode: shippingAddress.billingPincode,
              city: shippingAddress.billingCity,
              country: shippingAddress.billingCountry,
            },
          },
        },
      };
    }
    if (orderType == TABLE) {
      data1 = {
        ...data1,
        _idRestaurantTable: tableId,
        orderStatus: "placed_order",
        // userDetails: {
        //   name: "test User",
        // },
      };
    }

    if (!orderId) {
      data1 = {
        ...data1,
        orderType: orderType,
      };
    }
    let datas = JSON.stringify(data1);
    if (orderId && orderType == TABLE) {
      Api.updateOrder(datas, orderId).then((response) => {
        if (response.success) {
          setIsLoading(false);
          setOrderData({ ...orderData, ...data1 });
          setPaymentDrawerOpen(true);
          // if (!TEMP3REST.includes(restaurantId)) {
          //   setPage("cart");
          // } else {
          //   setPage("pay_now");
          // }
          // setCartItemList([]);
          // props.updateCartItems([]);
        } else {
          setIsLoading(false);
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
      });
    } else {
      Api.createOrder(datas).then((response) => {
        if (response.success) {
          let order_id = response?.data?._id;
          setOrderId(order_id);
          setOrderData(response?.data);
          // if (orderType == TABLE) {
          //   setIsLoading(false);
          //   if (!TEMP3REST.includes(restaurantId)) {
          //     setPage("cart");
          //   } else {
          //     setPage("pay_now");
          //   }
          //   props.updateCartItems([]);
          // } else {
          // orderPayment(order_id, transaction_id);
          setPage("order_confirm");
          setTransactionId("");
          props.clearCartData();
          setIsLoading(false);
          // }
        } else {
          if (orderType == TABLE) {
            setIsLoading(false);
          } else {
            setTransactionLoading(false);
          }
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
      });
    }
  };

  const orderPayment = (order_id, invoice_id) => {
    let data = new FormData();
    data.append("CardNumber", paymentDetail.cardNumber);
    data.append("cardExpiryMonth", paymentDetail.expMonth);
    data.append("cardExpiryYear", paymentDetail.expYear);
    data.append("CVV", paymentDetail.securityCode);

    setTransactionLoading(true);
    Api.orderPayment(data, invoice_id).then((response) => {
      if (typeof response.success == "undefined") {
        if (response.status == "SUCCESS") {
          getTransactionStatus(order_id, invoice_id);
        } else if (response.status == "FAILED") {
          updateOrder(order_id, invoice_id, "FAILED");
        } else if (response.status == "3DS") {
          setTransactionId(invoice_id);
          let isSafari =
            navigator.vendor &&
            navigator.vendor.indexOf("Apple") > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf("CriOS") == -1 &&
            navigator.userAgent.indexOf("FxiOS") == -1;
          if (isSafari) {
            setWindowOpenUrl(response.data.url);
          } else {
            window.open(response.data.url);
          }
        }
      } else {
        if (orderType != TABLE) {
          setOrderId("");
          setOrderData(null);
        }
        setTransactionId("");
        setTransactionLoading(false);
        if (response.success) {
        } else {
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
      }
    });
  };

  const updateOrder = (order_id, transaction_id, transaction_status) => {
    setTransactionLoading(true);
    let data1 = {
      transactionId: transaction_id,
      transactionStatus: transaction_status,
    };
    if (orderType == TABLE && transaction_status == "SUCCESS") {
      data1 = {
        ...data1,
        orderStatus: "completed",
      };
    }
    let datas = JSON.stringify(data1);
    Api.updateOrder(datas, order_id).then((response) => {
      setTransactionLoading(false);
      if (response.success) {
        if (transaction_status == "SUCCESS") {
          setPage("order_confirm");
          setTransactionId("");
          props.clearCartData();
        } else if (transaction_status == "FAILED") {
          if (orderType != TABLE) {
            setOrderId("");
            setOrderData(null);
          }
          setTransactionId("");
          setTransactionLoading(false);
          setMsgAlert({
            open: true,
            message: "Transaction Failed",
            msgType: "error",
          });
        }
      } else {
        if (orderType != TABLE) {
          setOrderId("");
          setOrderData(null);
        }
        setTransactionId("");
        setTransactionLoading(false);
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const onPayNowClick = () => {
    setIsLoading(false);
    setPage("checkout");
  };

  const onWindowOpenUrl = () => {
    setWindowOpenUrl("");
    window.open(windowOpenUrl);
  };

  const handleClosePaymentDrawer = () => {
    setPaymentDrawerOpen(false);
  }

  if (page == "pay_now") {
    return (
      <TableOrderPayNow
        onPayNow={onPayNowClick}
        onAddMore={onRestMenuNavigate}
        onlineTablePayment={onlineTablePayment}
      />
    );
  }

  if (transactionLoading) {
    if (windowOpenUrl) {
      return (
        <Box style={styles.transactionView} px={4}>
          <Button
            type="button"
            sx={styles.btnView}
            onClick={() => onWindowOpenUrl()}
          >
            {i18next.t("Continue to Bank Verification")}
          </Button>
        </Box>
      );
    }
    return (
      <Box style={styles.transactionView}>
        <Typography style={styles.transactionText}>
          Payment processing....
        </Typography>
        <br />
        <Typography style={styles.transactionText2}>
          Please wait while we process your transaction. Please do not hit the
          back button or refresh the page
        </Typography>
      </Box>
    );
  }

  if (isLoading) {
    return <Loader height={{ height: window.innerHeight }} />;
  }

  if (!TEMP3REST.includes(restaurantId) && page == "cart") {
    return (
      <Cart
        orderType={orderType}
        orderData={orderData}
        onlineTablePayment={onlineTablePayment}
        menuList={props.menuList}
        totalPrice={subTotalCartPrice}
        cartItemList={
          getCartItemList(props.cartItems, restaurantId).length
            ? cartItemList
            : []
        }
        currency={CURRENCY_SYMBOL[totalCartPriceUnit]}
        onCheckout={onBtnSubmit}
        onAddSubMenuItemToCart={onAddSubMenuItemToCart}
        onRestMenuNavigate={onRestMenuNavigate}
      />
    );
  }

  return (
    <Grid
      id="mainView"
      item
      xs={12}
      sx={{
        ...styles.mainView,
        paddingTop:
          page == "order_confirm" &&
          orderType == TABLE &&
          TEMP3REST.includes(restaurantId)
            ? 0
            : "1px",
        pb: pageBottom(page) ? 0 : "70px",
        backgroundColor:
          page == "cart"
            ? "#FFF"
            : pageBg(page)
            ? restTemplate == "temp1"
              ? "#f7fafb"
              : "#EEE"
            : "unset",
      }}
    >
      {page == "checkout" ? (
        <Box sx={styles.topImgView}>
          {props.restaurantDetail?.images?.length ? (
            <Box sx={styles.menuImage}>
              <img
                src={props.restaurantDetail.images[0]}
                width={"100%"}
                height={"100%"}
                style={{ objectFit: "cover" }}
                alt="restaurant"
              />
            </Box>
          ) : null}
          {/*{restTemplate == "temp1" ? (*/}
          <Box style={styles.backBtn} onClick={onBackBtnSubmit}>
            <BackIcon width={35} height={35} />
          </Box>
          {/*) : null}*/}
          {restTemplate == "temp1" ? (
            <Box style={styles.foodoIcon}>
              <FoodoIcon height={80} />
            </Box>
          ) : null}
        </Box>
      ) : null}

      {(page == "order_confirm" &&
        orderType == TABLE &&
        TEMP3REST.includes(restaurantId)) ||
      page == "checkout" ? null : (
        <ARWebCartHeader
          restTemplate={restTemplate}
          page={page}
          locale={props.locale}
          backClick={onBackBtnSubmit}
          cartItems={page == "order_confirm" ? [] : cartItemList}
          title={page_title[page]}
        />
      )}

      {cartItemList.length == 0 && page != "order_confirm" ? (
        <Box id="mainView" sx={styles.cartEmptyView}>
          <Typography sx={styles.cartEmptyText}>Cart Empty</Typography>
        </Box>
      ) : null}

      {page == "order_confirm" ? (
        <ARWebOrderConfirm
          restTemplate={restTemplate}
          locale={props.locale}
          orderType={orderType}
          orderId={orderId}
          shippingAddress={shippingAddress}
        />
      ) : null}

      {page == "checkout" || page == "order_confirm" ? (
        <ARWebCheckoutCartItem
          restTemplate={restTemplate}
          page={page}
          orderId={orderId}
          cartItems={cartItemList}
          menuList={props.menuList}
        />
      ) : null}

      {page == "checkout" ? (
        <ARWebPayment
          restTemplate={restTemplate}
          locale={props.locale}
          currency={CURRENCY_SYMBOL[totalCartPriceUnit]}
          paymentDetail={paymentDetail}
          updatePaymentDetail={updatePaymentDetail}
          onSetTipValue={onSetTipValue}
        />
      ) : null}

      {page == "checkout" && orderType == DELIVERY ? (
        <ARWebCheckout
          restTemplate={restTemplate}
          locale={props.locale}
          orderType={orderType}
          address={shippingAddress}
          setBillingSameAsDelivery={onSetBillingSameAsDelivery}
          addAddress={onAddAddress}
          listAddress={listAddress}
        />
      ) : null}

      {page == "checkout" || page == "order_confirm" ? (
        <ARWebBilling
          restTemplate={restTemplate}
          page={page}
          currency={CURRENCY_SYMBOL[totalCartPriceUnit]}
          discount={discountVal}
          tipValue={tipValue}
          taxValue={taxValue}
          subTotal={subTotalCartPrice}
          totalCartPrice={totalCartPrice}
        />
      ) : null}

      {page == "cart" && cartItemList.length > 0 ? (
        <ARWebCart
          page={page}
          locale={props.locale}
          cartItems={props.restaurantDetail?._id ? cartItemList : []}
          orderType={orderType}
          totalCartPrice={totalCartPrice}
          totalCartPriceUnit={CURRENCY_SYMBOL[totalCartPriceUnit]}
          onAddSubMenuItemToCart={onAddSubMenuItemToCart}
        />
      ) : null}

      {(page == "add_address" ||
        page == "add_new_address" ||
        page == "edit_address" ||
        page == "edit_billing_address") &&
      orderType == DELIVERY ? (
        <ARWebAddAddress
          restTemplate={restTemplate}
          page={page}
          locale={props.locale}
          editAddress={editAddress}
          onSubmitAddress={onSubmitAddAddress}
          onCancelAddress={onCancelAddAddress}
        />
      ) : null}

      {page === "list_address" && orderType === DELIVERY ? (
        <ARWebAddressList
          restTemplate={restTemplate}
          locale={props.locale}
          address={shippingAddress}
          addressList={props.userAddressList}
          editAddress={editAddressClick}
          addNewAddress={addNewAddressBtnClick}
          onSubmitAddress={onSubmitAddress}
          onCancelAddress={onCancelAddress}
        />
      ) : null}

      {page == "cart" ? (
        <ARWebCartFooter
          totalText={i18next.t("Total")}
          zIndex={1500}
          totalCartPriceUnit={CURRENCY_SYMBOL[totalCartPriceUnit]}
          totalCartPrice={totalCartPrice}
          btnEnable={true}
          cartItemsLength={cartItemList.length}
          btnText={
            orderType == TABLE
              ? i18next.t("Place Order")
              : i18next.t("Checkout")
          }
          onBtnSubmit={onBtnSubmit}
        />
      ) : null}

      {page == "order_confirm" ? (
        <Box px={1} pb={2}>
          <Button
            type="button"
            sx={{ ...styles.btnView, ...btnViewStyle[restTemplate] }}
            onClick={backToHomeBtn}
          >
            {i18next.t("Back to Home")}
          </Button>
        </Box>
      ) : null}

      {page == "checkout" ? (
        <Box px={2} pb={2}>
          <Button
            type="button"
            sx={{ ...styles.btnView, ...btnViewStyle[restTemplate] }}
            onClick={() => onBtnSubmit()}
          >
            {i18next.t("Pay Now")}
          </Button>
        </Box>
      ) : null}

      {/*{page == "order_confirm" && orderType == TABLE ? (*/}
      {/*  <ARWebLanding*/}
      {/*    page={"cart"}*/}
      {/*    title={"Your order placed successfully"}*/}
      {/*    onPay={() => null}*/}
      {/*    onBtnPress={onAddItemsClick}*/}
      {/*  />*/}
      {/*) : null}*/}

      {/*<ARWebOrderTypeModal*/}
      {/*  visible={orderTypeModalVisible && cartItemList.length}*/}
      {/*  restaurantName={props.restaurantDetail?.name}*/}
      {/*  restaurantLogo={props.restaurantDetail?.logo}*/}
      {/*  onUpdate={() => {*/}
      {/*    setOrderTypeModalVisible(false)*/}
      {/*  }}*/}
      {/*  onClose={() => {*/}
      {/*    setOrderTypeModalVisible(false)*/}
      {/*  }}*/}
      {/*/>*/}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />

      <PaymentDrawer open={paymentDrawerOpen} onClose={handleClosePaymentDrawer} amount={totalCartPrice} orderId={orderId} />
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    orderType: state.restaurantData.orderType,
    cartItems: state.restaurantData.cartItems,
    userAddressList: state.restaurantData.userAddressList,
    restaurantDetail: state.restaurantData.restaurantDetail,
    paymentDetail: state.restaurantData.paymentDetail,
    locale: state.restaurantData.locale,
    restData: state.apiData.restData,
    menuList: state.apiData.menuList,
    tableData: state.apiData.tableData,
    courseList: state.apiData.courseList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCartItems: (data) => dispatch(updateCartItems(data)),
    updateUserAddressList: (data) => dispatch(updateUserAddressList(data)),
    updatePaymentDetail: (data) => dispatch(updatePaymentDetail(data)),
    updateRestaurantDetail: (data) => dispatch(updateRestaurantDetail(data)),
    updateRestData: (data) => dispatch(updateRestData(data)),
    clearCartData: () => dispatch(clearCartData()),
    updateMenuList: (data) => dispatch(updateMenuList(data)),
    updateTableData: (data) => dispatch(updateTableData(data)),
    updateCourseList: (data) => dispatch(updateCourseList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantCart);

const styles = {
  backBtn: {
    cursor: "pointer",
    position: "absolute",
    top: 12,
    left: 12,
  },
  foodoIcon: {
    position: "absolute",
    top: 20,
    right: 12,
  },
  cartEmptyView: {
    backgroundColor: "#FFF",
    position: "fixed",
    top: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  cartEmptyText: {
    color: "#000",
    fontSize: 20,
    fontFamily: "GilroyBold",
    textAlign: "center",
  },
  transactionView: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  transactionText: {
    color: "#000",
    fontSize: 17,
    fontFamily: "GilroyBold",
    textAlign: "center",
  },
  transactionText2: {
    color: "#000",
    fontSize: 15,
    fontFamily: "GilroySemiBold",
    textAlign: "center",
  },
  mainView: {
    minHeight: window.innerHeight,
    position: "relative",
  },
  topImgView: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  },
  menuImage: {
    height: 100,
  },
  btnView: {
    backgroundColor: "#000000",
    cursor: "pointer",
    textTransform: "capitalize",
    color: "#FFF",
    fontFamily: "GilroyBold",
    fontSize: 18,
    borderRadius: "40px",
    height: 50,
    width: "100%",
    "&:hover": {
      backgroundColor: "#000000 !important",
    },
  },
};
