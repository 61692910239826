import * as React from "react";
import Box from "@mui/material/Box";

import { ReactComponent as NextIcon } from "../assets/images/next_icon.svg";

const circleSize = 65;

const AppNextBtn = (props) => {
  const { isCircle } = props;
  return (
    <Box
      sx={{ ...styles.prevNextImgMainView, borderRadius: isCircle ? "50%" : 0 }}
      onClick={() => props.onNextBtnClick()}
    >
      {props.image ? (
        <img alt="" src={props.image} style={styles.prevNextImg} />
      ) : (
        <NextIcon width={circleSize} height={circleSize} />
      )}
    </Box>
  );
};

export default AppNextBtn;

const styles = {
  prevNextImgMainView: {
    width: circleSize,
    height: circleSize,
    zIndex: 5,
    overflow: "hidden",
  },
  prevNextImg: {
    height: "100%",
    width: "100%",
    cursor: "pointer",
  },
};
