import React, { useState, useEffect } from "react";
import {
	Drawer,
	Box,
	Typography,
	Button,
	CircularProgress,
	TextField,
} from "@mui/material";
import {
	Elements,
	PaymentElement,
	useStripe,
	useElements,
	ExpressCheckoutElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from '@mui/icons-material/Close';
import { usePayment } from "../context/PaymentContext";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
console.log({ stripePromise })


const createPaymentIntent = async (amount, orderId) => {
	const amountInCents = Math.round(amount * 100);
	const applicationFeeAmount = Math.round(amountInCents * 0.02);

	const response = await fetch(
		`${process.env.REACT_APP_APP_API_URL}stripe/${orderId}/payment-intent`,
		{
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				action: "create",
				amount: amountInCents,
				currency: "gbp",
				orderId,
				connectedAccountId: "acct_1Pyb6CRgJ07pMihe",
				applicationFeeAmount: applicationFeeAmount,
			}),
		}
	);
	const data = await response.json();
	return {
		clientSecret: data.data.clientSecret,
		paymentIntentId: data.data.paymentIntentId,
	};
};

const updatePaymentIntent = async (
	paymentIntentId,
	customerName,
	customerEmail,
	orderId
) => {
	const response = await fetch(
		`${process.env.REACT_APP_APP_API_URL}stripe/${orderId}/payment-intent`,
		{
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				action: "update",
				paymentIntentId,
				customerName,
				customerEmail,
			}),
		}
	);
	return response.json();
};

const PaymentForm = ({
	amount,
	onSuccess,
	onCustomerInfoChange,
	paymentIntentId,
	isLoading,
	clientSecret,
}) => {
	const stripe = useStripe();
	const elements = useElements();
	const [errorMessage, setErrorMessage] = useState(null);
	const [isProcessing, setIsProcessing] = useState(false);
	const [isRetry, setIsRetry] = useState(false);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [expressCheckoutLoading, setExpressCheckoutLoading] = useState(false);

	const userDetails = useSelector(
		(state) => state.restaurantData.userAddressList[0]
	);

	const lineItems = useSelector((state) => state.restaurantData.cartItems);

	useEffect(() => {
		if (userDetails) {
			setName(userDetails.name);
			setEmail(userDetails.email);
		}
	}, [userDetails]);

	useEffect(() => {
		if (paymentIntentId && name && email) {
			onCustomerInfoChange(name, email);
		}
	}, [paymentIntentId, name, email, onCustomerInfoChange]);

	useEffect(() => {
		if (!elements) {
			return;
		}
		const expressCheckoutElement = elements.getElement(ExpressCheckoutElement);
		if (expressCheckoutElement) {
			expressCheckoutElement.on("confirm", handleExpressCheckoutConfirm);
		}
		return () => {
			if (expressCheckoutElement) {
				expressCheckoutElement.off("confirm", handleExpressCheckoutConfirm);
			}
		};
	}, [elements]);

	const handleExpressCheckoutConfirm = async () => {
		if (!stripe || !elements || !paymentIntentId) {
			console.error("Stripe.js has not loaded or paymentIntentId is missing.");
			return;
		}

		setIsProcessing(true);

		try {
			await onCustomerInfoChange(name, email);

			const { error, paymentIntent } = await stripe.confirmPayment({
				elements,
				clientSecret,
				confirmParams: {
					return_url: `${window.location.origin}/completion`,
					payment_method_data: {
						billing_details: {
							name,
							email,
						},
					},
				},
				redirect: "if_required",
			});

			if (error) {
				console.error("Error during payment confirmation:", error);
				setErrorMessage(error.message);
				setIsRetry(true);
			} else if (paymentIntent && paymentIntent.status === "succeeded") {
				console.log("Payment succeeded!");
				onSuccess(paymentIntent.id);
			} else if (paymentIntent && paymentIntent.status === "requires_action") {
				const { error: actionError } = await stripe.handleNextAction({
					clientSecret: paymentIntent.client_secret,
				});
				if (actionError) {
					console.error("Error during next action handling:", actionError);
					setErrorMessage(actionError.message);
					setIsRetry(true);
				} else {
					const { paymentIntent: updatedIntent } =
						await stripe.retrievePaymentIntent(paymentIntent.client_secret);
					if (updatedIntent.status === "succeeded") {
						console.log("Payment succeeded after 3D Secure!");
						onSuccess();
					} else if (updatedIntent.status === "requires_payment_method") {
						console.error("Payment failed. Requires new payment method.");
						setErrorMessage(
							"Payment failed. Please try another payment method."
						);
						setIsRetry(true);
					} else {
						console.error("Payment failed after 3D Secure authentication.");
						setErrorMessage("Payment failed after 3D Secure authentication.");
						setIsRetry(true);
					}
				}
			} else if (
				paymentIntent &&
				paymentIntent.status === "requires_payment_method"
			) {
				console.error("Payment failed. Requires new payment method.");
				setErrorMessage("Payment failed. Please try another payment method.");
				setIsRetry(true);
			} else {
				console.error("Unexpected payment status:", paymentIntent.status);
				setErrorMessage("Unexpected payment status. Please try again.");
				setIsRetry(true);
			}
		} catch (error) {
			console.error("Error during payment process:", error);
			setErrorMessage("An unexpected error occurred. Please try again.");
			setIsRetry(true);
		} finally {
			setIsProcessing(false);
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!stripe || !elements || !paymentIntentId) {
			console.error("Stripe.js has not loaded or paymentIntentId is missing.");
			return;
		}

		setIsProcessing(true);

		try {
			await onCustomerInfoChange(name, email);

			const { error: submitError } = await elements.submit();
			if (submitError) {
				setErrorMessage(submitError.message);
				setIsProcessing(false);
				return;
			}

			const { error, paymentIntent } = await stripe.confirmPayment({
				elements,
				confirmParams: {
					return_url: `${window.location.origin}/completion`,
					payment_method_data: {
						billing_details: {
							name,
							email,
						},
					},
				},
				redirect: "if_required",
			});

			if (error) {
				console.error("Error during payment confirmation:", error);
				setErrorMessage(error.message);
				setIsRetry(true);
			} else if (paymentIntent && paymentIntent.status === "succeeded") {
				console.log("Payment succeeded!");
				onSuccess(paymentIntent.id);
			} else if (paymentIntent && paymentIntent.status === "requires_action") {
				const { error: actionError } = await stripe.handleNextAction({
					clientSecret: paymentIntent.client_secret,
				});
				if (actionError) {
					console.error("Error during next action handling:", actionError);
					setErrorMessage(actionError.message);
					setIsRetry(true);
				} else {
					const { paymentIntent: updatedIntent } =
						await stripe.retrievePaymentIntent(paymentIntent.client_secret);
					if (updatedIntent.status === "succeeded") {
						console.log("Payment succeeded after 3D Secure!");
						onSuccess();
					} else if (updatedIntent.status === "requires_payment_method") {
						console.error("Payment failed. Requires new payment method.");
						setErrorMessage(
							"Payment failed. Please try another payment method."
						);
						setIsRetry(true);
					} else {
						console.error("Payment failed after 3D Secure authentication.");
						setErrorMessage("Payment failed after 3D Secure authentication.");
						setIsRetry(true);
					}
				}
			} else if (
				paymentIntent &&
				paymentIntent.status === "requires_payment_method"
			) {
				console.error("Payment failed. Requires new payment method.");
				setErrorMessage("Payment failed. Please try another payment method.");
				setIsRetry(true);
			} else {
				console.error("Unexpected payment status:", paymentIntent.status);
				setErrorMessage("Unexpected payment status. Please try again.");
				setIsRetry(true);
			}
		} catch (error) {
			console.error("Error during payment process:", error);
			setErrorMessage("An unexpected error occurred. Please try again.");
			setIsRetry(true);
		} finally {
			setIsProcessing(false);
		}
	};

	const handleNameChange = (e) => {
		const newName = e.target.value;
		setName(newName);
		onCustomerInfoChange(newName, email);
	};

	const handleEmailChange = (e) => {
		const newEmail = e.target.value;
		setEmail(newEmail);
		onCustomerInfoChange(name, newEmail);
	};

	const onReadyExpressCheckout = ({ availablePaymentMethods }) => {
		if (!availablePaymentMethods) {
			// No buttons will show
			setExpressCheckoutLoading(true);
		} else {
			// Optional: Animate in the Element
			setExpressCheckoutLoading(false);
		}
	};

	const onClickExpressCheckout = ({ resolve }) => {
		const options = {
			emailRequired: false,
			lineItems: lineItems.map((item) => ({
				name: item.name,
				amount: Math.round(item.price.value * 100),
			})),
			billingAddressRequired: false,
			shippingAddressRequired: false,
		};
		resolve(options);
	};

	const onCancelExpressCheckout = (event) => {
		console.log(event);
	};

	if (isLoading) {
		return (
			<Box
				display='flex'
				justifyContent='center'
				alignItems='center'
				height='100%'
				flexDirection='column'
			>
				<CircularProgress size={40} />
				<Typography variant='body1' style={{ marginTop: 16 }}>
					Preparing your payment...
				</Typography>
			</Box>
		);
	}

	return (
		<form onSubmit={handleSubmit}>
			<Typography variant='h6' gutterBottom>
				Complete Your Payment
			</Typography>
			<Typography variant='body1' gutterBottom>
				Amount to pay: £{amount}
			</Typography>

			<Box mb={2}>
				{expressCheckoutLoading ? (
					<CircularProgress size={24} />
				) : (
					<ExpressCheckoutElement
						onReady={onReadyExpressCheckout}
						onClick={onClickExpressCheckout}
						onCancel={onCancelExpressCheckout}
						options={{
							layout: {
								overflow: "never",
							},
							buttonType: {
								googlePay: "pay",
							},
							buttonHeight: 48,
						}}
					/>
				)}
			</Box>

			<Box mb={2}>
				<TextField
					label='Name'
					value={name}
					onChange={handleNameChange}
					fullWidth
					margin='normal'
					required
				/>
				<TextField
					label='Email'
					type='email'
					value={email}
					onChange={handleEmailChange}
					fullWidth
					margin='normal'
					required
				/>
			</Box>

			<PaymentElement
				options={{
					layout: {
						type: "accordion",
						spacedAccordionItems: true,
						visibleAccordionItemsCount: 0,
						defaultCollapsed: false,
					},
					defaultValues: {
						billingDetails: {
							name: name,
							email: email,
						},
					},
					paymentMethodOrder: ["card", "applePay", "googlePay", "link"],
				}}
			/>
			<Box my={2}>
				<Button
					type='submit'
					variant='contained'
					color='primary'
					fullWidth
					disabled={!stripe || isProcessing}
					sx={{
						height: 48,
						backgroundColor: isProcessing ? "grey.500" : "#1d3153",
					}}
				>
					{isProcessing ? (
						<CircularProgress size={24} />
					) : isRetry ? (
						"Retry Payment"
					) : (
						"Pay now"
					)}
				</Button>
			</Box>
			{errorMessage && (
				<Typography color='error' mt={2}>
					{errorMessage}
				</Typography>
			)}
		</form>
	);
};

const PaymentDrawer = ({ open, onClose, amount, orderId }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { setPaymentSuccessful } = usePayment();

	const [clientSecret, setClientSecret] = useState("");
	const [paymentIntentId, setPaymentIntentId] = useState("");
	const [paymentSuccess, setPaymentSuccess] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const restaurantId = location.pathname.split("/").pop();

	const elementsOptions = {
		clientSecret,
		appearance: {
			theme: "stripe",
			variables: {
				borderRadius: "4px",
			},
		},
	};

	useEffect(() => {
		const getClientSecret = async () => {
			if (open && amount) {
				setIsLoading(true);
				try {
					const { clientSecret, paymentIntentId } = await createPaymentIntent(
						amount,
						orderId
					);
					setClientSecret(clientSecret);
					setPaymentIntentId(paymentIntentId);
				} catch (error) {
					console.error("Error creating PaymentIntent:", error);
				} finally {
					setIsLoading(false);
				}
			}
		};

		getClientSecret();
	}, [open, amount, orderId]);

	const handleCustomerInfoChange = async (name, email) => {
		if (paymentIntentId) {
			try {
				await updatePaymentIntent(paymentIntentId, name, email, orderId);
			} catch (error) {
				console.error("Error updating PaymentIntent:", error);
			}
		}
	};

	const handleSuccess = (uniquePaymentIntentId) => {
		setPaymentSuccessful(true);
		setPaymentSuccess(true);

		navigate(`/order-success/${uniquePaymentIntentId}`, {
			state: {
				amount,
				orderId,
				restaurantId
			}
		})
	};

	return (
		<Drawer
			anchor='bottom'
			open={open}
			onClose={onClose}
			PaperProps={{
				sx: {
					borderTopRightRadius: 16,
					borderTopLeftRadius: 16,
					mx: 1,
					mt: 1,
					maxHeight: "calc(100% - 8px)",
				},
			}}
		>
			<Box
				role='presentation'
				sx={{
					p: 3,
					height: "100%",
					minHeight: "40vh",
				}}
			>
								<Button
				  onClick={onClose}
				  sx={{
					position: "absolute",
					right: 16,
					top: 16,
					backgroundColor: '#1d3153',
					color: "white",
					'&:hover': {
					  color: '#1d3153',
					  opacity: 1
					},
					'&:focus': {
					  color: '#1d3153',
					  opacity: 1
					},
					'&:active': {
					  color: '#1d3153',
					  opacity: 1
					},
					borderRadius: "50%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					boxShadow: "none",
					padding: '2px',
					minWidth: 24,
					minHeight: 24,
					opacity: 0.4
				  }}
				>
				  <CloseIcon sx={{ fontSize: '14px' }} />
				</Button>
				{paymentSuccess ? (
					<Box
						display='flex'
						flexDirection='column'
						justifyContent='center'
						alignItems='center'
						height='100%'
						textAlign='center'
					>
						<CheckCircleIcon style={{ fontSize: 80, color: "green" }} />
						<Typography variant='h4' color='success' gutterBottom>
							Payment Succeeded!
						</Typography>
						<Typography variant='body1'>
							Thank you for your payment. Your order will be ready shortly.
						</Typography>
					</Box>
				) : (
					clientSecret && (
						<Elements stripe={stripePromise} options={elementsOptions}>
							<PaymentForm
								amount={amount}
								onSuccess={handleSuccess}
								onCustomerInfoChange={handleCustomerInfoChange}
								paymentIntentId={paymentIntentId}
								isLoading={isLoading}
								clientSecret={clientSecret}
							/>
						</Elements>
					)
				)}
			</Box>
		</Drawer>
	);
};

export default PaymentDrawer;
